<template>
  <div class="row">
    <div class="col-12">
      <!-- 피해내역 -->
      <c-card title="LBL0002334" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <!-- 저장 -->
            <c-btn
              v-if="!disabled"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="tabParam"
              :mappingType="saveType"
              label="LBLSAVE"
              icon="save"
              @beforeAction="saveAccident"
              @btnCallback="saveCallback" 
            />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <!-- 물적피해내역 -->
            <c-textarea
              :disabled="disabled"
              :editable="editable"
              label="LBL0002335"
              name="materialDamageHistory"
              :rows="2"
              v-model="tabParam.materialDamageHistory">
            </c-textarea>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <!-- 인적피해내역 -->
            <c-textarea
              :disabled="disabled"
              :editable="editable"
              label="LBL0002336"
              :rows="2"
              name="humanDamageHistory"
              v-model="tabParam.humanDamageHistory">
            </c-textarea>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <!-- 사고분류 -->
            <c-select
              :editable="editable"
              :disabled="disabled"
              type="edit"
              codeGroupCd="IIM_DAMAGE_CLASS_CD"
              itemText="codeName"
              itemValue="code"
              name="damageClassCd"
              label="LBL0002337"
              v-model="tabParam.damageClassCd"
            ></c-select>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <!-- 상해정도 -->
            <c-select
              :editable="editable"
              :disabled="disabled"
              type="edit"
              codeGroupCd="IIM_WOUND_CD"
              itemText="codeName"
              itemValue="code"
              name="woundCd"
              label="LBL0002338"
              v-model="tabParam.woundCd"
            ></c-select>
          </div>
        </template>
      </c-card>
      <div class="col-lg-12 col-md-12 col-sm-12">
        <!-- 사고자 인적사항 목록 -->
        <!-- 인적피해일 시 사고자 정보를 입력하세요. -->
        <c-table
          ref="table"
          title="LBL0002339"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="tabParam.accidentVictimModelList"
          :filtering="false"
          :columnSetting="false"
          :checkClickFlag="false"
          :usePaging="false"
          :editable="editable && !disabled"
          :noDataLabel="$message('MSG0000702')"
          selection="multiple"
          rowKey="victimId"
          @table-data-change= "tableDataChange"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline>
              <!-- 내부직원 추가 -->
              <c-btn v-if="editable && !disabled" :showLoading="false" label="LBL0002347" icon="add" @btnClicked="add" />
              <!-- 외부직원 추가 -->
              <c-btn v-if="editable && !disabled" :showLoading="false" label="LBL0002348" icon="add" @btnClicked="addRow" />
              <!-- 제외 -->
              <c-btn v-if="editable && !disabled && tabParam.accidentVictimModelList.length > 0" :showLoading="false"  label="LBLEXCEPT" icon="remove" @btnClicked="remove" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name ==='victimName'">
              <c-text-column
                v-if="props.row['insideOutsideTypeCd'] === 'N'"
                :editable="editable"
                :props="props"
                :col="col"
                :disabled="disabled"
                v-model="props.row['victimName']"
                @datachange="datachange(props)"
              />
              <span v-else>
                {{props.row['victimName']}}
              </span>
            </template>
            <template v-if="col.name ==='birthDate'">
              <c-datepicker
                v-if="props.row['insideOutsideTypeCd'] === 'N'"
                class="tableDatepicker"
                :editable="editable"
                :disabled="disabled"
                v-model="props.row['birthDate']"
                @datachange="datachange(props)"
              />
              <span v-else>
                {{props.row['birthDate']}}
              </span>
            </template>
            <template v-if="col.name ==='career'">
              <c-text-column
                v-if="props.row['insideOutsideTypeCd'] === 'N'"
                :editable="editable"
                :props="props"
                :col="col"
                :disabled="disabled"
                v-model="props.row['career']"
                @datachange="datachange(props)"
              />
              <span v-else>
                {{props.row['career']}}
              </span>
            </template>
          </template>
        </c-table>
      </div>
      <div class="col-12">
        <!-- 피해정도 관련 사진 -->
        <c-upload 
          :attachInfo="attachInfo"
          label="LBL0002340"
          :editable="editable&&!disabled">
        </c-upload>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>

import transactionConfig from '@/js/transactionConfig';
import selectConfig from '@/js/selectConfig';
import { uid } from 'quasar'
export default {
  name: 'process-degree-damage',

  props: {
    tabParam: {
      type: Object,
      default: () => ({
        materialDamageHistory: '',
        humanDamageHistory: '',
        iimAccidentId: '',
        accidentStatusCd: '',
        accidentVictimModelList: [],
        deleteAccidentVictimModelList: [],
        damageClassCd: null,
        woundCd: null,
      }),
    },
  },
  data() {
    return {
      editable: true,
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'PROCESS_DEGREE_DAMAGE',
        taskKey: '',
      },
      grid: {
        columns: [
          {
            name: 'victimName',
            field: 'victimName',
            // 이름
            label: 'LBLNAME',
            style: 'width:80px',
            type: 'custom',
            align: 'center',
            sortable: false
          },
          {
            name: 'birthDate',
            field: 'birthDate',
            // 생년월일
            label: 'LBL0002341',
            style: 'width:80px',
            align: 'center',
            type: 'custom',
            sortable: false
          },
          {
            name: 'career',
            field: 'career',
            // 직급
            label: 'LBL0002342',
            style: 'width:60px',
            align: 'center',
            type: 'custom',
            sortable: false
          },
          // {
          //   name: 'insideOutsideTypeName',
          //   field: 'insideOutsideTypeName',
          //   label: '내/외부',
          //   align: 'center',
          //   style: 'width:50px',
          //   sortable: false,
          // },
          {
            name: 'degreeDamage',
            field: 'degreeDamage',
            // 피해상해정도
            label: 'LBL0002343',
            style: 'width:120px',
            type: "textarea",
            align: 'left',
            sortable: false
          },
          {
            name: 'expectTreatmentDate',
            field: 'expectTreatmentDate',
            // 예상치료기간
            label: 'LBL0002344',
            type: "date",
            range: true,
            style: 'width:150px',
            align: 'center',
            sortable: false
          },
          {
            name: 'actionMatters',
            field: 'actionMatters',
            // 조치사항
            label: 'LBL0002345',
            style: 'width:250px',
            type: "textarea",
            align: 'left',
            sortable: false
          },
        ],
        data: [],
        height: '270px'
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      saveUrl: 'transactionConfig.sop.iim.accident.process.insert.url',
      saveType: 'POST',
      isSave: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.editable && Boolean(this.tabParam.accidentStatusCd) && this.tabParam.accidentStatusCd === 'ISPC000005'
    }
  },
  watch: {
    'tabParam.iimAccidentId'() {
      this.setTaskKey();
    },
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.updateUrl = transactionConfig.sop.iim.accident.process.update.damage.url;
      this.getUrl = selectConfig.sop.iim.accident.process.get.url;
      // code setting
      this.setTaskKey();
    },
    setTaskKey() {
      let taskKey = this.tabParam && this.tabParam.iimAccidentId ? this.tabParam.iimAccidentId : '';
      this.attachInfo.taskKey = taskKey
    },
    add() {
      this.popupOptions.title = 'LBL0002346'; // 사고자 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.tabParam.accidentVictimModelList, {
            victimUserId: _item.userId,
          });
          if (index === -1) {
            this.tabParam.accidentVictimModelList.splice(0, 0, {
              iimAccidentId: this.tabParam.iimAccidentId,
              victimId: uid(),
              victimUserId: _item.userId,
              victimName: _item.userName,
              birthDate: _item.birthDate,
              insideOutsideTypeName: '내부',
              insideOutsideTypeCd: 'Y',
              age: '',
              career: _item.spotName,
              degreeDamage: '',
              expectTreatmentDate: [],
              expectTreatmentStartDate: '',
              expectTreatmentEndDate: '',
              actionMatters: '',
              editFlag: 'C'
            })
          }
        })
      }
    },
    addRow() {
      this.tabParam.accidentVictimModelList.splice(0, 0, {
        iimAccidentId: this.tabParam.iimAccidentId,
        victimId: uid(),
        victimName: '',
        birthDate: '',
        insideOutsideTypeName: '외부',
        insideOutsideTypeCd: 'N',
        age: '',
        career: '',
        degreeDamage: '',
        expectTreatmentDate: [],
        expectTreatmentStartDate: '',
        expectTreatmentEndDate: '',
        actionMatters: '',
        editFlag: 'C'
      })
    },
    remove() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.tabParam.accidentVictimModelList = this.$_.reject(this.tabParam.accidentVictimModelList, { victimId: item.victimId })
          if (!this.tabParam.deleteAccidentVictimModelList) {
              this.tabParam.deleteAccidentVictimModelList = []
          }
          if (this.$_.findIndex(this.tabParam.deleteAccidentVictimModelList, { victimId: item.victimId }) === -1
          && item.editFlag !== 'C') {
            this.tabParam.deleteAccidentVictimModelList.push(item)
          }
          this.tabParam.accidentVictimModelList = this.$_.reject(this.tabParam.accidentVictimModelList, { victimId: item.victimId })
        })
      }
    },
    saveAccident() {
      this.saveUrl = this.updateUrl;
      this.saveType = 'PUT';
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGSAVE', // 저장하시겠습니까?
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.tabParam.regUserId = this.$store.getters.user.userId
          this.tabParam.chgUserId = this.$store.getters.user.userId
          
          console.log(this.tabParam.accidentVictimModelList)
          this.isSave = !this.isSave;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('getDetail')
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    tableDataChange(props, col) {
      if (col.name === 'expectTreatmentDate') {
        if (props.row.expectTreatmentDate && props.row.expectTreatmentDate.length > 0) {
          this.$set(props.row, 'expectTreatmentStartDate', props.row.expectTreatmentDate[0])
          this.$set(props.row, 'expectTreatmentEndDate', props.row.expectTreatmentDate[1])
        } else {
          this.$set(props.row, 'expectTreatmentStartDate', '')
          this.$set(props.row, 'expectTreatmentEndDate', '')
        }
      }
    }
  }
};
</script>
